import { useSWRConfig } from 'swr'

import { TOTAL_ITEMS_KEY } from '../../constants/constants'
import { getBagItems } from '../../services/queries'
import { shoppingCartCache } from '../../utils/cache'

/**
 * Updates the number of items in the cart
 */
export const useUpdateTotalItems = () => {
	const { mutate } = useSWRConfig()

	const updateTotalItems = async (totalItems: number) => {
		await shoppingCartCache().addKeyToCache(TOTAL_ITEMS_KEY, totalItems)
		mutate(
			[getBagItems],
			{ findTotalItemsByUser: { totalItems } },
			{ revalidate: false }
		)
	}

	return { updateTotalItems }
}
