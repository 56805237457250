import { MAX_RETRIES } from '../constants/constants'

/**
 * Function to recover shop session
 * It is limited to MAX_RELOAD times (default: 3)
 * It is called only in no stock modal
 * TODO: remove this after decoupling the shopping cart from the Shop
 */
export const refresh = (push: Function): void => {
	const reloadsCount =
		parseInt(sessionStorage.getItem('reloadsCount') as string, 10) || 0
	if (reloadsCount <= MAX_RETRIES) {
		sessionStorage.setItem('reloadsCount', `${reloadsCount + 1}`)
		// go to home page

		push('/')
	}
}
