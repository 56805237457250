'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'
import { type KeyedMutator, default as useSWRImmutable } from 'swr'

import { TOTAL_ITEMS_KEY } from '../../constants/constants'
import { useCartState } from '../../context/CartProvider'
import { getBagItems } from '../../services/queries'
import { shoppingCartCache } from '../../utils/cache'
import { useUpdateTotalItems } from '../mutations/useUpdateTotalItems'
import { useCartFetcher } from '../useCartFetcher'

export interface CartDataType {
	findTotalItemsByUser: {
		totalItems: number
	}
}

interface UseBagTotalItemsType {
	totalItems: number | undefined
	mutate: KeyedMutator<CartDataType>
	error: Error[] | null
}

interface CacheFetcherProps {
	token: string
	countryISO: string
}

const cacheFetcher = async (args: CacheFetcherProps) => {
	shoppingCartCache(args.token, args.countryISO)
	const cacheData = await shoppingCartCache().getKeyFromCache(TOTAL_ITEMS_KEY)
	if (cacheData !== undefined) {
		return cacheData
	} else {
		throw new Error('no totalItem in cache')
	}
}

export const useBagTotalItems = (): UseBagTotalItemsType => {
	const { showShoppingCart } = useCartState()
	const { fetcher } = useCartFetcher<CartDataType, undefined>()
	const { updateTotalItems } = useUpdateTotalItems()
	const { token } = useSession()

	const {
		country: { countryISO },
	} = useMasterData()

	// query to handle cache storage total items
	useSWRImmutable(
		token && countryISO ? { token, countryISO } : null,
		cacheFetcher,
		{
			onSuccess: async (cacheData) => {
				await updateTotalItems(Number(cacheData))
			},
			onError: () => {
				if (
					!showShoppingCart &&
					data?.findTotalItemsByUser?.totalItems === undefined
				) {
					mutate()
				}
			},
		}
	)

	const { data, error, mutate } = useSWRImmutable<CartDataType>(
		token ? [getBagItems] : null,
		fetcher,
		{
			revalidateOnMount: false,
			onSuccess: async (newData) => {
				await updateTotalItems(newData?.findTotalItemsByUser?.totalItems)
			},
		}
	)

	return {
		totalItems: data?.findTotalItemsByUser?.totalItems,
		error,
		mutate,
	}
}
