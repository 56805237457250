import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import type {
	AddItemMutation,
	ShoppingCartAddItemInput,
	ShoppingCartDto,
} from 'codegen/src/gateway/graphql'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { DEFAULT_LOOK_ID } from 'product/constants/constants'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { DEFAULT_SELLER } from '../../constants/constants'
import { useCartDispatch, useCartState } from '../../context/CartProvider'
import type { ItemType } from '../../services/graphql.types'
import { addItem as addItemQuery } from '../../services/mutations'
import type { Item } from '../../types/ControllerType'
import { checkItemStock } from '../../utils/checkItemStock'
import { getMngItems } from '../../utils/getMngItems'
import { useCartFetcher } from '../useCartFetcher'
import { type ErrorItem, useErrorManagement } from '../useErrorManagement'
import { useNotifications } from '../useNotifications'
import { useUpdateBag } from './useUpdateBag'

export interface AddType {
	showShoppingCart: boolean
}

export interface ProductType {
	collectionId?: string
	colorId: string
	customColor?: string
	customPosition?: string
	customSize?: string
	customType?: string
	lookId: string
	productId: string
	needleWork?: string
	sizeId: string
	seller: string
	lastUnits: boolean
	layoutView?: string
}

interface AddItemVariable {
	item: ShoppingCartAddItemInput
}

export const useAddToCart = ({ showShoppingCart }: AddType) => {
	const { showPreview } = useCartState()
	const dispatch = useCartDispatch()
	const { isLargeOrGreater } = useResponsive()
	const { addErrorManagement } = useErrorManagement()
	const { addItemNotification } = useNotifications()
	const {
		country: { countryISO },
	} = useMasterData()
	const { fetcher } = useCartFetcher<AddItemMutation, AddItemVariable>()

	const { updateBag } = useUpdateBag()
	const { sendAddToCart } = useEECEvents()

	const getLookId = (lookId: string) => lookId?.toString() || DEFAULT_LOOK_ID

	const setPreventClose = () => {
		if (showPreview && !showShoppingCart) {
			dispatch({ preventClose: true })
		}
	}

	const getDispatch = () => {
		if (!showPreview && !showShoppingCart) {
			dispatch({ showPreview: 'auto' })
		}
	}

	const prepareItem = (product: ProductType) => {
		const {
			collectionId,
			colorId,
			customColor,
			customPosition,
			customSize,
			customType,
			lookId,
			productId,
			needleWork,
			sizeId,
			seller = DEFAULT_SELLER,
		} = product
		const customized = {
			collectionId: collectionId?.toString(),
			customColor,
			customPosition,
			customSize,
			customType,
			needleWork,
		}

		const item: Item = {
			colorId,
			productId,
			seller,
			sizeId,
			look: getLookId(lookId),
		}

		if (
			customized &&
			Object.values(customized).some((attribute) => !!attribute)
		) {
			item.customized = customized
		}
		return item
	}
	const addItem = async (
		product: ProductType,
		location: string,
		productListId?: string,
		notShowNotification = false
	) => {
		setPreventClose()

		try {
			const item = prepareItem(product)
			await checkItemStock(item, countryISO)

			const response = await fetcher([addItemQuery, { item }])

			updateBag(response.addItem as ShoppingCartDto)

			sendAddToCart({
				item: { ...item, lastUnits: product.lastUnits, quantity: 1, index: 1 },
				mngItems: getMngItems(response.addItem?.items as ItemType[]),
				listId: productListId,
				location,
			})

			getDispatch()

			if ((!isLargeOrGreater || showShoppingCart) && !notShowNotification) {
				addItemNotification()
			}
			sessionStorage.removeItem('reloadsCount')
		} catch (error) {
			addErrorManagement(
				Array.isArray(error) ? (error as ErrorItem[]) : (error as ErrorItem)
			)
		}
	}

	return { addItem }
}
