'use client'

import { useGraphQL } from 'hooks/useGraphQL/useGraphQL'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { url as urlUtil } from 'utils/url'

import { SHOPPING_CART_SERVICES_ENDPOINT } from '../constants/constants'
import {
	type GraphQlError,
	getErrorHandler,
	useHandleErrors,
} from './useHandleErrors'

type FetcherArgs<TVariables> = [
	key: string,
	variables?: TVariables,
	optionalArgs?: string,
]

export const defaultVariables = {
	orderBy: { createdAt: 'DESC' },
}

export const fetchUrl = urlUtil(
	`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${SHOPPING_CART_SERVICES_ENDPOINT}`
)

const isGraphQLResponseError = (error: unknown): error is Error[] =>
	Array.isArray(error) && error.length > 0 && error[0].message !== undefined

export const useCartFetcher = <TData, TVariables>() => {
	const { fetcher: fetcherGraphQL } = useGraphQL()
	const { channel } = useMasterData()
	const { handleError } = useHandleErrors()

	async function fetcher(args: FetcherArgs<TVariables>): Promise<TData> {
		const [query, variables] = args
		const response = await fetcherGraphQL<TData, TVariables, Error>({
			query,
			url: fetchUrl,
			variables: {
				channel,
				...defaultVariables,
				...(variables ?? {}),
			} as TVariables,
		})

		if (!response) {
			throw new Error('No response received from GraphQL')
		} else if (isGraphQLResponseError(response)) {
			handleError(
				getErrorHandler(query),
				response as unknown as GraphQlError | GraphQlError[]
			)
			throw response
		} else {
			return response as TData
		}
	}

	return {
		fetcher,
	}
}
