import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useDynatrace } from 'monitoring/contexts/DynatraceProvider'
import { useSession } from 'session/src/hooks/useSession'

import {
	DYNATRACE_ERROR_BY_PRIORITY,
	DYNATRACE_ERROR_TYPE,
} from '../constants/constants'

export interface GraphQlError {
	message: string
	locations: unknown[]
	path: string[]
	extensions: {
		parameters: {
			limitUnits: number
			limitAmount: number
		}
		classification: string
	}
}

const errorMapping: Record<string, DYNATRACE_ERROR_TYPE> = {
	AddItem: DYNATRACE_ERROR_TYPE.ADD,
	ClearItem: DYNATRACE_ERROR_TYPE.CLEAR,
	RemoveItem: DYNATRACE_ERROR_TYPE.REMOVE,
	MergeWithGuest: DYNATRACE_ERROR_TYPE.MERGE,
	FindTotalItemsByUser: DYNATRACE_ERROR_TYPE.QUERY_TOTAL_ITEMS,
	FindByUser: DYNATRACE_ERROR_TYPE.QUERY_FIND_BY_USER,
}

export const getErrorHandler = (query: string): DYNATRACE_ERROR_TYPE => {
	const queryType: string | undefined = Object.keys(errorMapping).find(
		(key: string) => query.includes(key)
	)
	return queryType ? errorMapping[queryType] : DYNATRACE_ERROR_TYPE.GENERIC
}

export function useHandleErrors() {
	const { logged } = useSession()
	const { channel, platform } = useMasterData()
	const { reportCustomError } = useDynatrace()

	const handleError = (
		key: DYNATRACE_ERROR_TYPE,
		error: GraphQlError | GraphQlError[]
	) => {
		let message = ''
		let path = ''

		if (error) {
			if (Array.isArray(error)) {
				message = error
					.map((err: GraphQlError) => err?.message ?? '')
					.join(', ')
				path = error.flatMap((err: GraphQlError) => err?.path ?? []).join(', ')
			} else {
				message = error?.message ?? ''
				path = error?.path?.join(', ') ?? ''
			}
		}

		const hint = {
			message,
			channel,
			platform,
			isLogged: logged,
			priority: DYNATRACE_ERROR_BY_PRIORITY[key],
		}
		reportCustomError({
			key,
			message: path,
			hint: JSON.stringify(hint),
		})
		console.error(`${key} - ${path}`)
	}

	return { handleError }
}
