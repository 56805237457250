import { useNavigation } from 'hooks/useNavigation/useNavigation'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { PAGE_TYPE_BOLSA } from '../constants/constants'
import { useCartDispatch, useCartState } from '../context/CartProvider'
import type { ControllerType } from '../types/ControllerType'
import { refresh } from '../utils/refresh'
import { useAddToCart } from './mutations/useAddToCart'
import { useBagTotalItems } from './queries/useBagTotalItems'

export const useCartController = (): ControllerType => {
	const cartState = useCartState()
	const { pageType } = useMasterData()
	const { showShoppingCart, showPreview } = cartState
	const { totalItems } = useBagTotalItems()
	const dispatch = useCartDispatch()
	const { navigate } = useNavigation()
	const { addItem } = useAddToCart({
		showShoppingCart: pageType === PAGE_TYPE_BOLSA ?? false,
	})

	const handleToggle = (): boolean => {
		if (!showShoppingCart) {
			dispatch({ showPreview: !showPreview })
			return true
		}
		return false
	}

	const handleOpen = () => {
		if (!showPreview && !showShoppingCart) {
			dispatch({ showPreview: true })
		}
	}

	const handleClose = () => {
		dispatch({ showPreview: false })
	}

	return {
		status: !!showPreview,
		toggle: handleToggle,
		open: handleOpen,
		close: handleClose,
		totalItems: totalItems || 0,
		refresh: () => refresh(navigate),
		add: addItem,
		addWithoutNotification: addItem,
	}
}
