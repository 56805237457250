/* eslint-disable */
import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
	  }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	/** An RFC-3339 compliant Full Date Scalar */
	Date: { input: any; output: any }
	/** A slightly refined version of RFC-3339 compliant DateTime Scalar */
	DateTime: { input: any; output: any }
}

export type AccountBalance = {
	__typename?: 'AccountBalance'
	applied?: Maybe<Scalars['Boolean']['output']>
	balance?: Maybe<MoneyDto>
	leftToPay?: Maybe<MoneyDto>
	remaining?: Maybe<MoneyDto>
	used?: Maybe<MoneyDto>
}

export type AdditionalPromoCodeData = {
	birthDate?: InputMaybe<Scalars['Date']['input']>
	identifierNumber?: InputMaybe<Scalars['String']['input']>
	lastName?: InputMaybe<Scalars['String']['input']>
}

export type BillingAddress = {
	__typename?: 'BillingAddress'
	address1?: Maybe<Scalars['String']['output']>
	address2?: Maybe<Scalars['String']['output']>
	address3?: Maybe<Scalars['String']['output']>
	areaCode?: Maybe<Scalars['String']['output']>
	city?: Maybe<Scalars['String']['output']>
	company?: Maybe<Scalars['Boolean']['output']>
	companyName?: Maybe<Scalars['String']['output']>
	country?: Maybe<Scalars['String']['output']>
	countryId?: Maybe<Scalars['String']['output']>
	districtId?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	firstName?: Maybe<Scalars['String']['output']>
	fullAddress?: Maybe<Scalars['String']['output']>
	id?: Maybe<Scalars['String']['output']>
	isValidAddress?: Maybe<Scalars['Boolean']['output']>
	lastName?: Maybe<Scalars['String']['output']>
	neighborhood?: Maybe<Scalars['String']['output']>
	pec?: Maybe<Scalars['String']['output']>
	phoneNumber?: Maybe<Scalars['String']['output']>
	recipientCode?: Maybe<Scalars['String']['output']>
	state?: Maybe<Scalars['String']['output']>
	stateId?: Maybe<Scalars['String']['output']>
	subDistrictId?: Maybe<Scalars['String']['output']>
	taxOffice?: Maybe<Scalars['String']['output']>
	tin?: Maybe<Scalars['String']['output']>
	zipCode?: Maybe<Scalars['String']['output']>
}

export type BillingAddressInput = {
	address1?: InputMaybe<Scalars['String']['input']>
	address2?: InputMaybe<Scalars['String']['input']>
	address3?: InputMaybe<Scalars['String']['input']>
	areaCode?: InputMaybe<Scalars['String']['input']>
	city?: InputMaybe<Scalars['String']['input']>
	company?: InputMaybe<Scalars['Boolean']['input']>
	companyName?: InputMaybe<Scalars['String']['input']>
	country?: InputMaybe<Scalars['String']['input']>
	countryId?: InputMaybe<Scalars['String']['input']>
	districtId?: InputMaybe<Scalars['String']['input']>
	email?: InputMaybe<Scalars['String']['input']>
	firstName?: InputMaybe<Scalars['String']['input']>
	fullAddress?: InputMaybe<Scalars['String']['input']>
	lastName?: InputMaybe<Scalars['String']['input']>
	neighborhood?: InputMaybe<Scalars['String']['input']>
	pec?: InputMaybe<Scalars['String']['input']>
	phoneNumber?: InputMaybe<Scalars['String']['input']>
	recipientCode?: InputMaybe<Scalars['String']['input']>
	state?: InputMaybe<Scalars['String']['input']>
	subDistrictId?: InputMaybe<Scalars['String']['input']>
	taxOffice?: InputMaybe<Scalars['String']['input']>
	tin?: InputMaybe<Scalars['String']['input']>
	zipCode?: InputMaybe<Scalars['String']['input']>
}

export type CodeDto = {
	__typename?: 'CodeDto'
	name?: Maybe<Scalars['String']['output']>
	type?: Maybe<Scalars['String']['output']>
}

export type CommunicationMessage = {
	__typename?: 'CommunicationMessage'
	id?: Maybe<Scalars['String']['output']>
	key?: Maybe<Scalars['String']['output']>
}

export type CountryFeatures = {
	__typename?: 'CountryFeatures'
	hasPremiumDeliveryMethods?: Maybe<Scalars['Boolean']['output']>
}

export type CreateBillingAddressInput = {
	billingAddress: BillingAddressInput
	channel: Scalars['String']['input']
}

export type CreateDeliveryAddressInput = {
	channel: Scalars['String']['input']
	deliveryAddressId: Scalars['String']['input']
	deliveryId?: InputMaybe<Scalars['String']['input']>
	deliveryMethodId?: InputMaybe<Scalars['String']['input']>
	legacyCustomerId: Scalars['String']['input']
}

export type CustomizedData = {
	__typename?: 'CustomizedData'
	collectionId?: Maybe<Scalars['String']['output']>
	customColor?: Maybe<Scalars['String']['output']>
	customPosition?: Maybe<Scalars['String']['output']>
	customSize?: Maybe<Scalars['String']['output']>
	customType?: Maybe<Scalars['String']['output']>
	needleWork?: Maybe<Scalars['String']['output']>
}

export type CustomizedInput = {
	collectionId?: InputMaybe<Scalars['String']['input']>
	customColor?: InputMaybe<Scalars['String']['input']>
	customPosition?: InputMaybe<Scalars['String']['input']>
	customSize?: InputMaybe<Scalars['String']['input']>
	customType?: InputMaybe<Scalars['String']['input']>
	needleWork?: InputMaybe<Scalars['String']['input']>
}

export type DeliveryAddress = {
	__typename?: 'DeliveryAddress'
	address1?: Maybe<Scalars['String']['output']>
	address2?: Maybe<Scalars['String']['output']>
	address3?: Maybe<Scalars['String']['output']>
	areaCode?: Maybe<Scalars['String']['output']>
	city?: Maybe<Scalars['String']['output']>
	country?: Maybe<Scalars['String']['output']>
	countryId?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	firstName?: Maybe<Scalars['String']['output']>
	fullAddress?: Maybe<Scalars['String']['output']>
	id?: Maybe<Scalars['String']['output']>
	isMainAddress?: Maybe<Scalars['Boolean']['output']>
	isValidAddress?: Maybe<Scalars['Boolean']['output']>
	lastName?: Maybe<Scalars['String']['output']>
	phoneNumber?: Maybe<Scalars['String']['output']>
	state?: Maybe<Scalars['String']['output']>
	stateId?: Maybe<Scalars['String']['output']>
	tin?: Maybe<Scalars['String']['output']>
	zipCode?: Maybe<Scalars['String']['output']>
}

export type DeliveryDates = {
	__typename?: 'DeliveryDates'
	from?: Maybe<Scalars['String']['output']>
	to?: Maybe<Scalars['String']['output']>
}

export type DeliveryMethod = {
	__typename?: 'DeliveryMethod'
	category?: Maybe<DeliveryMethodCategory>
	estimatedDelivery?: Maybe<EstimatedDelivery>
	id: Scalars['String']['output']
	isAlternativeForDangerousGoods?: Maybe<Scalars['Boolean']['output']>
	isPremium?: Maybe<Scalars['Boolean']['output']>
	price?: Maybe<MoneyDto>
	selected?: Maybe<Scalars['Boolean']['output']>
}

export enum DeliveryMethodCategory {
	Droppoint = 'DROPPOINT',
	Home = 'HOME',
	Mango = 'MANGO',
	Store = 'STORE',
}

export type DraftPurchase = {
	__typename?: 'DraftPurchase'
	accountBalance?: Maybe<AccountBalance>
	billingAddress?: Maybe<BillingAddress>
	billingAddressId?: Maybe<Scalars['String']['output']>
	communicationMessages?: Maybe<Array<Maybe<CommunicationMessage>>>
	countryFeatures?: Maybe<CountryFeatures>
	currency: Scalars['String']['output']
	deliveries: Array<Maybe<DraftPurchaseDelivery>>
	deliveryMethods?: Maybe<Array<Maybe<DeliveryMethod>>>
	giftVoucher?: Maybe<GiftVoucher>
	id: Scalars['String']['output']
	invoiceType?: Maybe<Scalars['String']['output']>
	mangoLikes?: Maybe<MangoLikes>
	preSaleTotal?: Maybe<MoneyDto>
	promotions?: Maybe<DraftPurchasePromotions>
	selectedDeliveryMethod: SelectedDeliveryMethod
	summary?: Maybe<Summary>
	userId: Scalars['String']['output']
}

export type DraftPurchaseDelivery = {
	__typename?: 'DraftPurchaseDelivery'
	deliveryAddress?: Maybe<DeliveryAddress>
	deliveryAddressId?: Maybe<Scalars['String']['output']>
	deliveryDates?: Maybe<DeliveryDates>
	id?: Maybe<Scalars['String']['output']>
	items?: Maybe<Array<Maybe<ItemCheckoutDto>>>
	order?: Maybe<Scalars['Int']['output']>
	seller?: Maybe<Scalars['String']['output']>
	warehouse?: Maybe<Scalars['String']['output']>
}

export type DraftPurchasePromotions = {
	__typename?: 'DraftPurchasePromotions'
	codes?: Maybe<Array<Maybe<CodeDto>>>
	discount?: Maybe<MoneyDto>
	freeShipping?: Maybe<Scalars['Boolean']['output']>
	someItemsOnly?: Maybe<Scalars['Boolean']['output']>
}

export type DropPointData = {
	__typename?: 'DropPointData'
	dropPointAddress: Scalars['String']['output']
	dropPointCity: Scalars['String']['output']
	dropPointName: Scalars['String']['output']
	dropPointPostalCode: Scalars['String']['output']
}

export type DropPointDataInput = {
	dropPointAddress: Scalars['String']['input']
	dropPointCity: Scalars['String']['input']
	dropPointName: Scalars['String']['input']
	dropPointPostalCode: Scalars['String']['input']
}

export type DropPointDeliveryDataInput = {
	dropPointData?: InputMaybe<DropPointDataInput>
	dropPointId: Scalars['String']['input']
	pickingData: PickingDataInput
}

export type EstimatedDelivery = {
	__typename?: 'EstimatedDelivery'
	dateFrom?: Maybe<Scalars['String']['output']>
	dateTo?: Maybe<Scalars['String']['output']>
	message?: Maybe<Scalars['String']['output']>
}

export type GetDraftPurchaseInput = {
	channel: Scalars['String']['input']
}

export type GiftVoucher = {
	__typename?: 'GiftVoucher'
	applied?: Maybe<MoneyDto>
	balance?: Maybe<MoneyDto>
	code?: Maybe<Scalars['String']['output']>
}

export type GiftVoucherInput = {
	channel: Scalars['String']['input']
	code: Scalars['String']['input']
	cvv?: InputMaybe<Scalars['String']['input']>
}

export type HomeDeliveryDataInput = {
	deliveryAddressId: Scalars['String']['input']
}

export type InitializeCheckoutInput = {
	channel: Scalars['String']['input']
	countryIso: Scalars['String']['input']
}

export type ItemCheckoutDto = {
	__typename?: 'ItemCheckoutDto'
	channel: Scalars['String']['output']
	colorId: Scalars['String']['output']
	customized: Scalars['Boolean']['output']
	customizedData?: Maybe<CustomizedData>
	isDangerousGood?: Maybe<Scalars['Boolean']['output']>
	itemId: Scalars['String']['output']
	look?: Maybe<Scalars['String']['output']>
	mangoLikesDiscount?: Maybe<MoneyDto>
	outOfStock?: Maybe<Scalars['Boolean']['output']>
	price: PriceDto
	productId: Scalars['String']['output']
	productInfo?: Maybe<ItemCheckoutProductInfo>
	promotion?: Maybe<ItemPromotion>
	quantity: Scalars['Int']['output']
	seller: Scalars['String']['output']
	sizeId: Scalars['String']['output']
	sku: Scalars['String']['output']
}

export type ItemCheckoutProductInfo = {
	__typename?: 'ItemCheckoutProductInfo'
	colorName?: Maybe<Scalars['String']['output']>
	image?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	sizeName?: Maybe<Scalars['String']['output']>
}

export type ItemDto = {
	__typename?: 'ItemDto'
	channel: Scalars['String']['output']
	colorId: Scalars['String']['output']
	createdAt: Scalars['DateTime']['output']
	customized: Scalars['Boolean']['output']
	customizedData?: Maybe<CustomizedData>
	hasLowStock: Scalars['Boolean']['output']
	itemId: Scalars['String']['output']
	look?: Maybe<Scalars['String']['output']>
	outOfStock?: Maybe<Scalars['Boolean']['output']>
	price: PriceDto
	productId: Scalars['String']['output']
	quantity: Scalars['Int']['output']
	seller: Scalars['String']['output']
	sizeId: Scalars['String']['output']
	sku: Scalars['String']['output']
	updatedAt: Scalars['DateTime']['output']
	warehouses?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type ItemPromotion = {
	__typename?: 'ItemPromotion'
	code?: Maybe<Scalars['String']['output']>
	price?: Maybe<MoneyDto>
}

export type LegacyParamsInput = {
	saleType?: InputMaybe<Scalars['String']['input']>
	salesPeriod?: InputMaybe<Scalars['Boolean']['input']>
	stateCode?: InputMaybe<Scalars['Int']['input']>
}

export type Limits = {
	__typename?: 'Limits'
	maxAmount: MoneyDto
	maxUnits: Scalars['Int']['output']
}

export type LowestDto = {
	__typename?: 'LowestDto'
	promo?: Maybe<MoneyDto>
	sale?: Maybe<MoneyDto>
}

export type MangoDeliveryDataInput = {
	pickingData: PickingDataInput
}

export type MangoLikes = {
	__typename?: 'MangoLikes'
	discount?: Maybe<MoneyDto>
	minPointsNeeded?: Maybe<Scalars['Int']['output']>
	type?: Maybe<MangoLikesType>
}

export enum MangoLikesType {
	Applicable = 'APPLICABLE',
	IsApplied = 'IS_APPLIED',
	MinPointsRequired = 'MIN_POINTS_REQUIRED',
	NotApplicable = 'NOT_APPLICABLE',
}

export type MergeDraftPurchaseInput = {
	channel: Scalars['String']['input']
}

export type Metadata = {
	__typename?: 'Metadata'
	limits: Limits
	limitsExceeded: Scalars['Boolean']['output']
}

export type MoneyDto = {
	__typename?: 'MoneyDto'
	amount: Scalars['Float']['output']
	currency: Scalars['String']['output']
	decimals: Scalars['Int']['output']
}

export type Mutation = {
	__typename?: 'Mutation'
	addItem?: Maybe<ShoppingCartDto>
	applyAccountBalance?: Maybe<DraftPurchase>
	applyGiftVoucher?: Maybe<DraftPurchase>
	applyMangoLikes?: Maybe<DraftPurchase>
	applyPromotionalCode?: Maybe<DraftPurchase>
	cancelAccountBalance?: Maybe<DraftPurchase>
	cancelBillingAddress?: Maybe<DraftPurchase>
	cancelGiftVoucher?: Maybe<DraftPurchase>
	cancelMangoLikes?: Maybe<DraftPurchase>
	cancelPromotionalCode?: Maybe<DraftPurchase>
	clearItem?: Maybe<ShoppingCartDto>
	confirmRemoveCheckoutItem?: Maybe<DraftPurchase>
	createBillingAddress?: Maybe<DraftPurchase>
	createDeliveryAddress?: Maybe<DraftPurchase>
	initializeCheckout?: Maybe<ValidPurchase>
	initializeDraftPurchase?: Maybe<DraftPurchase>
	mergeDraftPurchase?: Maybe<DraftPurchase>
	mergeFrom?: Maybe<ShoppingCartDto>
	refreshDeliveryMethods?: Maybe<DraftPurchase>
	removeCheckoutItem?: Maybe<DraftPurchase>
	removeDangerousItems?: Maybe<DraftPurchase>
	removeItem?: Maybe<ShoppingCartDto>
	selectDeliveryMethod?: Maybe<DraftPurchase>
	selectHomeDeliveryMethod?: Maybe<DraftPurchase>
	selectedDraftPurchaseDeliveryMethod?: Maybe<DraftPurchase>
	updateBillingAddress?: Maybe<DraftPurchase>
	updateDeliveryAddress?: Maybe<DraftPurchase>
}

export type MutationAddItemArgs = {
	channel: Scalars['String']['input']
	item: ShoppingCartAddItemInput
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}

export type MutationApplyAccountBalanceArgs = {
	channel: Scalars['String']['input']
}

export type MutationApplyGiftVoucherArgs = {
	giftVoucherInput: GiftVoucherInput
}

export type MutationApplyMangoLikesArgs = {
	channel: Scalars['String']['input']
}

export type MutationApplyPromotionalCodeArgs = {
	promotionalCodeInput: PromotionalCodeInput
}

export type MutationCancelAccountBalanceArgs = {
	channel: Scalars['String']['input']
}

export type MutationCancelBillingAddressArgs = {
	channel: Scalars['String']['input']
}

export type MutationCancelGiftVoucherArgs = {
	giftVoucherInput: GiftVoucherInput
}

export type MutationCancelMangoLikesArgs = {
	channel: Scalars['String']['input']
}

export type MutationCancelPromotionalCodeArgs = {
	promotionalCodeInput: PromotionalCodeInput
}

export type MutationClearItemArgs = {
	channel: Scalars['String']['input']
	itemId: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}

export type MutationConfirmRemoveCheckoutItemArgs = {
	removeItemInput: RemoveItemInput
}

export type MutationCreateBillingAddressArgs = {
	createBillingAddressInput: CreateBillingAddressInput
}

export type MutationCreateDeliveryAddressArgs = {
	createDeliveryAddressInput: CreateDeliveryAddressInput
}

export type MutationInitializeCheckoutArgs = {
	initializeCheckoutInput: InitializeCheckoutInput
}

export type MutationInitializeDraftPurchaseArgs = {
	initializeCheckoutInput: InitializeCheckoutInput
}

export type MutationMergeDraftPurchaseArgs = {
	mergeDraftPurchaseInput: MergeDraftPurchaseInput
}

export type MutationMergeFromArgs = {
	channel: Scalars['String']['input']
	cleanup?: InputMaybe<Scalars['Boolean']['input']>
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}

export type MutationRefreshDeliveryMethodsArgs = {
	refreshDeliveryMethodsInput: RefreshDeliveryMethodsInput
}

export type MutationRemoveCheckoutItemArgs = {
	removeItemInput: RemoveItemInput
}

export type MutationRemoveDangerousItemsArgs = {
	removeDangerousItemsInput: RemoveDangerousItemsInput
}

export type MutationRemoveItemArgs = {
	channel: Scalars['String']['input']
	itemId: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}

export type MutationSelectDeliveryMethodArgs = {
	selectDeliveryMethodInput: SelectDeliveryMethodInput
}

export type MutationSelectHomeDeliveryMethodArgs = {
	selectHomeDeliveryMethodInput: SelectHomeDeliveryMethodInput
}

export type MutationSelectedDraftPurchaseDeliveryMethodArgs = {
	selectedDeliveryMethodInput: SelectedDeliveryMethodInput
}

export type MutationUpdateBillingAddressArgs = {
	updateBillingAddressInput: UpdateBillingAddressInput
}

export type MutationUpdateDeliveryAddressArgs = {
	updateDeliveryAddressInput: UpdateDeliveryAddressInput
}

export type OriginalDto = {
	__typename?: 'OriginalDto'
	outlet?: Maybe<MoneyDto>
	shop?: Maybe<MoneyDto>
}

export type PickingData = {
	__typename?: 'PickingData'
	address?: Maybe<Scalars['String']['output']>
	city?: Maybe<Scalars['String']['output']>
	countryCode?: Maybe<Scalars['String']['output']>
	countryId?: Maybe<Scalars['String']['output']>
	email?: Maybe<Scalars['String']['output']>
	name?: Maybe<Scalars['String']['output']>
	phone?: Maybe<Scalars['String']['output']>
	phonePrefix?: Maybe<Scalars['String']['output']>
	postalCode?: Maybe<Scalars['String']['output']>
	provinceId?: Maybe<Scalars['String']['output']>
	provinceName?: Maybe<Scalars['String']['output']>
	surname?: Maybe<Scalars['String']['output']>
}

export type PickingDataInput = {
	address: Scalars['String']['input']
	city: Scalars['String']['input']
	countryCode: Scalars['String']['input']
	countryId?: InputMaybe<Scalars['String']['input']>
	email: Scalars['String']['input']
	name: Scalars['String']['input']
	phone: Scalars['String']['input']
	phonePrefix: Scalars['String']['input']
	postalCode: Scalars['String']['input']
	provinceId?: InputMaybe<Scalars['String']['input']>
	provinceName?: InputMaybe<Scalars['String']['input']>
	surname: Scalars['String']['input']
}

export type PreviousDto = {
	__typename?: 'PreviousDto'
	lowest?: Maybe<LowestDto>
	original?: Maybe<OriginalDto>
}

export type PriceDto = {
	__typename?: 'PriceDto'
	current: MoneyDto
	discountRate?: Maybe<Scalars['Float']['output']>
	initial: MoneyDto
	opi?: Maybe<Scalars['Boolean']['output']>
	original: MoneyDto
	previous?: Maybe<PreviousDto>
	promotionName?: Maybe<Scalars['String']['output']>
	star?: Maybe<Scalars['Boolean']['output']>
	type?: Maybe<Scalars['String']['output']>
}

export type PromotionalCodeInput = {
	additionalData?: InputMaybe<AdditionalPromoCodeData>
	channel: Scalars['String']['input']
	code: Scalars['String']['input']
}

export type Query = {
	__typename?: 'Query'
	findByUser?: Maybe<ShoppingCartDto>
	findTotalItemsByUser?: Maybe<TotalItems>
	getDraftPurchase?: Maybe<DraftPurchase>
}

export type QueryFindByUserArgs = {
	channel: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}

export type QueryFindTotalItemsByUserArgs = {
	channel: Scalars['String']['input']
}

export type QueryGetDraftPurchaseArgs = {
	getDraftPurchaseInput: GetDraftPurchaseInput
}

export type RefreshDeliveryMethodsInput = {
	channel: Scalars['String']['input']
	postalCode: Scalars['String']['input']
}

export type RemoveDangerousItemsInput = {
	channel: Scalars['String']['input']
}

export type RemoveItemInput = {
	channel: Scalars['String']['input']
	itemId: Scalars['String']['input']
}

export type SelectDeliveryMethodInput = {
	channel: Scalars['String']['input']
	deliveryMethodId?: InputMaybe<Scalars['String']['input']>
	postalCode?: InputMaybe<Scalars['String']['input']>
}

export type SelectHomeDeliveryMethodInput = {
	channel: Scalars['String']['input']
	deliveryAddressId?: InputMaybe<Scalars['String']['input']>
	deliveryMethodId?: InputMaybe<Scalars['String']['input']>
	postalCode?: InputMaybe<Scalars['String']['input']>
}

export type SelectedDeliveryMethod =
	| SelectedDropPointDeliveryMethod
	| SelectedHomeDeliveryMethod
	| SelectedMangoDeliveryMethod
	| SelectedStoreDeliveryMethod

export type SelectedDeliveryMethodInput = {
	category: Scalars['String']['input']
	channel: Scalars['String']['input']
	deliveryMethodId: Scalars['String']['input']
	dropPointDeliveryData?: InputMaybe<DropPointDeliveryDataInput>
	homeDeliveryData?: InputMaybe<HomeDeliveryDataInput>
	legacyCustomerId?: InputMaybe<Scalars['String']['input']>
	mangoDeliveryData?: InputMaybe<MangoDeliveryDataInput>
	storeDeliveryData?: InputMaybe<StoreDeliveryDataInput>
}

export type SelectedDropPointDeliveryMethod = {
	__typename?: 'SelectedDropPointDeliveryMethod'
	deliveryMethodId?: Maybe<Scalars['String']['output']>
	dropPointData?: Maybe<DropPointData>
	dropPointId?: Maybe<Scalars['String']['output']>
	hasDangerousGoodsRestrictions?: Maybe<Scalars['Boolean']['output']>
	pickingData?: Maybe<PickingData>
}

export type SelectedHomeDeliveryMethod = {
	__typename?: 'SelectedHomeDeliveryMethod'
	deliveryAddressId?: Maybe<Scalars['String']['output']>
	deliveryMethodId?: Maybe<Scalars['String']['output']>
	hasDangerousGoodsRestrictions?: Maybe<Scalars['Boolean']['output']>
}

export type SelectedMangoDeliveryMethod = {
	__typename?: 'SelectedMangoDeliveryMethod'
	deliveryMethodId?: Maybe<Scalars['String']['output']>
	hasDangerousGoodsRestrictions?: Maybe<Scalars['Boolean']['output']>
	pickingData?: Maybe<PickingData>
}

export type SelectedStoreDeliveryMethod = {
	__typename?: 'SelectedStoreDeliveryMethod'
	deliveryMethodId?: Maybe<Scalars['String']['output']>
	hasDangerousGoodsRestrictions?: Maybe<Scalars['Boolean']['output']>
	pickingData?: Maybe<PickingData>
	storeId?: Maybe<Scalars['String']['output']>
}

export type ShoppingCartAddItemInput = {
	colorId: Scalars['String']['input']
	customized?: InputMaybe<CustomizedInput>
	legacyParams?: InputMaybe<LegacyParamsInput>
	look?: InputMaybe<Scalars['String']['input']>
	productId: Scalars['String']['input']
	seller: Scalars['String']['input']
	sizeId: Scalars['String']['input']
}

export type ShoppingCartDeliveryDto = {
	__typename?: 'ShoppingCartDeliveryDto'
	items: Array<Maybe<ItemDto>>
	order: Scalars['Int']['output']
}

export type ShoppingCartDto = {
	__typename?: 'ShoppingCartDto'
	channel: Scalars['String']['output']
	createdAt: Scalars['DateTime']['output']
	deliveries: Array<Maybe<ShoppingCartDeliveryDto>>
	isEmpty: Scalars['Boolean']['output']
	items: Array<Maybe<ItemDto>>
	merged: Scalars['Boolean']['output']
	metadata: Metadata
	summary: SummaryDto
	totalItems: Scalars['Int']['output']
	updatedAt: Scalars['DateTime']['output']
	userId: Scalars['ID']['output']
}

export type ShoppingCartOrderByInput = {
	createdAt?: InputMaybe<Sort>
	updatedAt?: InputMaybe<Sort>
}

export enum Sort {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type StoreDeliveryDataInput = {
	pickingData: PickingDataInput
	storeId: Scalars['String']['input']
}

export type Summary = {
	__typename?: 'Summary'
	areTaxesCalculated: Scalars['Boolean']['output']
	giftVoucherAmount?: Maybe<MoneyDto>
	loyaltyDiscount?: Maybe<MoneyDto>
	promoDiscount?: Maybe<MoneyDto>
	shipping: MoneyDto
	subtotal: MoneyDto
	subtotalWithShipping: MoneyDto
	taxes?: Maybe<MoneyDto>
	total?: Maybe<MoneyDto>
	totalItems?: Maybe<Scalars['Int']['output']>
}

export type SummaryDto = {
	__typename?: 'SummaryDto'
	deliveryCost: MoneyDto
	deliveryMethodId: Scalars['String']['output']
	freeShipping: Scalars['Boolean']['output']
	freeShippingAvailable: Scalars['Boolean']['output']
	original: MoneyDto
	promoteShippingToStore: Scalars['Boolean']['output']
	remainingCostToBeFreeShipping: MoneyDto
	saved: MoneyDto
	subtotal: MoneyDto
	taxable: Scalars['Boolean']['output']
	total: MoneyDto
}

export type TotalItems = {
	__typename?: 'TotalItems'
	totalItems: Scalars['Int']['output']
}

export type UpdateBillingAddressInput = {
	billingAddress: BillingAddressInput
	channel: Scalars['String']['input']
}

export type UpdateDeliveryAddressInput = {
	channel: Scalars['String']['input']
	deliveryAddressId: Scalars['String']['input']
	deliveryId?: InputMaybe<Scalars['String']['input']>
	deliveryMethodId?: InputMaybe<Scalars['String']['input']>
}

export type ValidPurchase = {
	__typename?: 'ValidPurchase'
	shoppingCartId: Scalars['String']['output']
}

export type FragmentItemsFieldsFragment = {
	__typename?: 'ItemDto'
	colorId: string
	customized: boolean
	itemId: string
	look?: string | null
	productId: string
	hasLowStock: boolean
	quantity: number
	seller: string
	sizeId: string
	outOfStock?: boolean | null
	warehouses?: Array<string | null> | null
	price: {
		__typename?: 'PriceDto'
		discountRate?: number | null
		promotionName?: string | null
		star?: boolean | null
		type?: string | null
		current: { __typename?: 'MoneyDto'; currentPrice: number }
		initial: { __typename?: 'MoneyDto'; initialPrice: number }
		previous?: {
			__typename?: 'PreviousDto'
			lowest?: {
				__typename?: 'LowestDto'
				promo?: { __typename?: 'MoneyDto'; amount: number } | null
				sale?: { __typename?: 'MoneyDto'; amount: number } | null
			} | null
			original?: {
				__typename?: 'OriginalDto'
				shop?: { __typename?: 'MoneyDto'; amount: number } | null
				outlet?: { __typename?: 'MoneyDto'; amount: number } | null
			} | null
		} | null
	}
} & { ' $fragmentName'?: 'FragmentItemsFieldsFragment' }

export type FragmentSummaryDtoFieldsFragment = {
	__typename?: 'SummaryDto'
	freeShipping: boolean
	promoteShippingToStore: boolean
	taxable: boolean
	deliveryCost: { __typename?: 'MoneyDto'; deliveryAmount: number }
	subtotal: { __typename?: 'MoneyDto'; subTotalPrice: number }
	total: { __typename?: 'MoneyDto'; totalPrice: number }
	remainingCostToBeFreeShipping: { __typename?: 'MoneyDto'; untilFree: number }
	saved: { __typename?: 'MoneyDto'; amount: number }
	original: { __typename?: 'MoneyDto'; amount: number }
} & { ' $fragmentName'?: 'FragmentSummaryDtoFieldsFragment' }

export type AddItemMutationVariables = Exact<{
	channel: Scalars['String']['input']
	item: ShoppingCartAddItemInput
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}>

export type AddItemMutation = {
	__typename?: 'Mutation'
	addItem?: {
		__typename?: 'ShoppingCartDto'
		totalItems: number
		items: Array<
			| ({ __typename?: 'ItemDto' } & {
					' $fragmentRefs'?: {
						FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
					}
			  })
			| null
		>
		deliveries: Array<{
			__typename?: 'ShoppingCartDeliveryDto'
			order: number
			items: Array<
				| ({ __typename?: 'ItemDto' } & {
						' $fragmentRefs'?: {
							FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
						}
				  })
				| null
			>
		} | null>
		summary: { __typename?: 'SummaryDto' } & {
			' $fragmentRefs'?: {
				FragmentSummaryDtoFieldsFragment: FragmentSummaryDtoFieldsFragment
			}
		}
		metadata: { __typename?: 'Metadata'; limitsExceeded: boolean }
	} | null
}

export type MergeWithGuestMutationVariables = Exact<{
	channel: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}>

export type MergeWithGuestMutation = {
	__typename?: 'Mutation'
	mergeFrom?: {
		__typename?: 'ShoppingCartDto'
		merged: boolean
		totalItems: number
		items: Array<
			| ({ __typename?: 'ItemDto' } & {
					' $fragmentRefs'?: {
						FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
					}
			  })
			| null
		>
		deliveries: Array<{
			__typename?: 'ShoppingCartDeliveryDto'
			order: number
			items: Array<
				| ({ __typename?: 'ItemDto' } & {
						' $fragmentRefs'?: {
							FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
						}
				  })
				| null
			>
		} | null>
		summary: { __typename?: 'SummaryDto' } & {
			' $fragmentRefs'?: {
				FragmentSummaryDtoFieldsFragment: FragmentSummaryDtoFieldsFragment
			}
		}
		metadata: { __typename?: 'Metadata'; limitsExceeded: boolean }
	} | null
}

export type MergeWithGuestCleanupMutationVariables = Exact<{
	channel: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
	cleanup?: InputMaybe<Scalars['Boolean']['input']>
}>

export type MergeWithGuestCleanupMutation = {
	__typename?: 'Mutation'
	mergeFrom?: { __typename?: 'ShoppingCartDto'; merged: boolean } | null
}

export type RemoveItemMutationVariables = Exact<{
	channel: Scalars['String']['input']
	itemId: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}>

export type RemoveItemMutation = {
	__typename?: 'Mutation'
	removeItem?: {
		__typename?: 'ShoppingCartDto'
		totalItems: number
		items: Array<
			| ({ __typename?: 'ItemDto' } & {
					' $fragmentRefs'?: {
						FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
					}
			  })
			| null
		>
		deliveries: Array<{
			__typename?: 'ShoppingCartDeliveryDto'
			order: number
			items: Array<
				| ({ __typename?: 'ItemDto' } & {
						' $fragmentRefs'?: {
							FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
						}
				  })
				| null
			>
		} | null>
		summary: { __typename?: 'SummaryDto' } & {
			' $fragmentRefs'?: {
				FragmentSummaryDtoFieldsFragment: FragmentSummaryDtoFieldsFragment
			}
		}
		metadata: { __typename?: 'Metadata'; limitsExceeded: boolean }
	} | null
}

export type ClearItemMutationVariables = Exact<{
	channel: Scalars['String']['input']
	itemId: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}>

export type ClearItemMutation = {
	__typename?: 'Mutation'
	clearItem?: {
		__typename?: 'ShoppingCartDto'
		totalItems: number
		items: Array<
			| ({ __typename?: 'ItemDto' } & {
					' $fragmentRefs'?: {
						FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
					}
			  })
			| null
		>
		deliveries: Array<{
			__typename?: 'ShoppingCartDeliveryDto'
			order: number
			items: Array<
				| ({ __typename?: 'ItemDto' } & {
						' $fragmentRefs'?: {
							FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
						}
				  })
				| null
			>
		} | null>
		summary: { __typename?: 'SummaryDto' } & {
			' $fragmentRefs'?: {
				FragmentSummaryDtoFieldsFragment: FragmentSummaryDtoFieldsFragment
			}
		}
		metadata: { __typename?: 'Metadata'; limitsExceeded: boolean }
	} | null
}

export type FindByUserQueryVariables = Exact<{
	channel: Scalars['String']['input']
	orderBy?: InputMaybe<ShoppingCartOrderByInput>
}>

export type FindByUserQuery = {
	__typename?: 'Query'
	findByUser?: {
		__typename?: 'ShoppingCartDto'
		totalItems: number
		merged: boolean
		deliveries: Array<{
			__typename?: 'ShoppingCartDeliveryDto'
			order: number
			items: Array<
				| ({ __typename?: 'ItemDto' } & {
						' $fragmentRefs'?: {
							FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
						}
				  })
				| null
			>
		} | null>
		items: Array<
			| ({ __typename?: 'ItemDto' } & {
					' $fragmentRefs'?: {
						FragmentItemsFieldsFragment: FragmentItemsFieldsFragment
					}
			  })
			| null
		>
		summary: { __typename?: 'SummaryDto' } & {
			' $fragmentRefs'?: {
				FragmentSummaryDtoFieldsFragment: FragmentSummaryDtoFieldsFragment
			}
		}
		metadata: { __typename?: 'Metadata'; limitsExceeded: boolean }
	} | null
}

export type FindTotalItemsByUserQueryVariables = Exact<{
	channel: Scalars['String']['input']
}>

export type FindTotalItemsByUserQuery = {
	__typename?: 'Query'
	findTotalItemsByUser?: {
		__typename?: 'TotalItems'
		totalItems: number
	} | null
}

export type FragmentPromotionsFieldsFragment = {
	__typename?: 'DraftPurchasePromotions'
	freeShipping?: boolean | null
	someItemsOnly?: boolean | null
	codes?: Array<{
		__typename?: 'CodeDto'
		type?: string | null
		name?: string | null
	} | null> | null
	discount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentPromotionsFieldsFragment' }

export type FragmentDeliveryMethodsFragment = {
	__typename?: 'DeliveryMethod'
	category?: DeliveryMethodCategory | null
	id: string
	isAlternativeForDangerousGoods?: boolean | null
	isPremium?: boolean | null
	estimatedDelivery?: {
		__typename?: 'EstimatedDelivery'
		dateFrom?: string | null
		dateTo?: string | null
	} | null
	price?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentDeliveryMethodsFragment' }

export type FragmentGiftVoucherFieldsFragment = {
	__typename?: 'GiftVoucher'
	code?: string | null
	balance?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	applied?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentGiftVoucherFieldsFragment' }

export type FragmentAccountBalanceFieldsFragment = {
	__typename?: 'AccountBalance'
	applied?: boolean | null
	balance?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	used?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	remaining?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	leftToPay?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentAccountBalanceFieldsFragment' }

type FragmentSelectedDeliveryMethod_SelectedDropPointDeliveryMethod_Fragment = {
	__typename?: 'SelectedDropPointDeliveryMethod'
	deliveryMethodId?: string | null
	dropPointId?: string | null
	hasDangerousGoodsRestrictions?: boolean | null
	dropPointData?: {
		__typename?: 'DropPointData'
		dropPointName: string
		dropPointAddress: string
		dropPointCity: string
		dropPointPostalCode: string
	} | null
	pickingData?: {
		__typename?: 'PickingData'
		address?: string | null
		city?: string | null
		countryCode?: string | null
		name?: string | null
		surname?: string | null
		email?: string | null
		phone?: string | null
		phonePrefix?: string | null
		postalCode?: string | null
		provinceId?: string | null
		provinceName?: string | null
	} | null
} & {
	' $fragmentName'?: 'FragmentSelectedDeliveryMethod_SelectedDropPointDeliveryMethod_Fragment'
}

type FragmentSelectedDeliveryMethod_SelectedHomeDeliveryMethod_Fragment = {
	__typename?: 'SelectedHomeDeliveryMethod'
	deliveryMethodId?: string | null
	deliveryAddressId?: string | null
	hasDangerousGoodsRestrictions?: boolean | null
} & {
	' $fragmentName'?: 'FragmentSelectedDeliveryMethod_SelectedHomeDeliveryMethod_Fragment'
}

type FragmentSelectedDeliveryMethod_SelectedMangoDeliveryMethod_Fragment = {
	__typename?: 'SelectedMangoDeliveryMethod'
	deliveryMethodId?: string | null
	hasDangerousGoodsRestrictions?: boolean | null
	pickingData?: {
		__typename?: 'PickingData'
		address?: string | null
		city?: string | null
		countryCode?: string | null
		name?: string | null
		surname?: string | null
		email?: string | null
		phone?: string | null
		phonePrefix?: string | null
		postalCode?: string | null
		provinceId?: string | null
		provinceName?: string | null
	} | null
} & {
	' $fragmentName'?: 'FragmentSelectedDeliveryMethod_SelectedMangoDeliveryMethod_Fragment'
}

type FragmentSelectedDeliveryMethod_SelectedStoreDeliveryMethod_Fragment = {
	__typename?: 'SelectedStoreDeliveryMethod'
	deliveryMethodId?: string | null
	storeId?: string | null
	hasDangerousGoodsRestrictions?: boolean | null
	pickingData?: {
		__typename?: 'PickingData'
		address?: string | null
		city?: string | null
		countryCode?: string | null
		name?: string | null
		surname?: string | null
		email?: string | null
		phone?: string | null
		phonePrefix?: string | null
		postalCode?: string | null
		provinceId?: string | null
		provinceName?: string | null
	} | null
} & {
	' $fragmentName'?: 'FragmentSelectedDeliveryMethod_SelectedStoreDeliveryMethod_Fragment'
}

export type FragmentSelectedDeliveryMethodFragment =
	| FragmentSelectedDeliveryMethod_SelectedDropPointDeliveryMethod_Fragment
	| FragmentSelectedDeliveryMethod_SelectedHomeDeliveryMethod_Fragment
	| FragmentSelectedDeliveryMethod_SelectedMangoDeliveryMethod_Fragment
	| FragmentSelectedDeliveryMethod_SelectedStoreDeliveryMethod_Fragment

export type FragmentSummaryFieldsFragment = {
	__typename?: 'Summary'
	totalItems?: number | null
	areTaxesCalculated: boolean
	loyaltyDiscount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	promoDiscount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	giftVoucherAmount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	shipping: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	}
	subtotal: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	}
	subtotalWithShipping: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	}
	total?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
	taxes?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentSummaryFieldsFragment' }

export type FragmentDeliveryAddressFieldsFragment = {
	__typename?: 'DeliveryAddress'
	address1?: string | null
	address2?: string | null
	address3?: string | null
	areaCode?: string | null
	city?: string | null
	country?: string | null
	email?: string | null
	firstName?: string | null
	fullAddress?: string | null
	id?: string | null
	lastName?: string | null
	phoneNumber?: string | null
	state?: string | null
	tin?: string | null
	zipCode?: string | null
	isValidAddress?: boolean | null
} & { ' $fragmentName'?: 'FragmentDeliveryAddressFieldsFragment' }

export type FragmentBillingAddressFieldsFragment = {
	__typename?: 'BillingAddress'
	address1?: string | null
	address2?: string | null
	address3?: string | null
	areaCode?: string | null
	city?: string | null
	country?: string | null
	email?: string | null
	firstName?: string | null
	fullAddress?: string | null
	id?: string | null
	lastName?: string | null
	phoneNumber?: string | null
	state?: string | null
	tin?: string | null
	zipCode?: string | null
	isValidAddress?: boolean | null
} & { ' $fragmentName'?: 'FragmentBillingAddressFieldsFragment' }

export type FragmentItemFieldsFragment = {
	__typename?: 'ItemCheckoutDto'
	channel: string
	colorId: string
	customized: boolean
	itemId: string
	look?: string | null
	outOfStock?: boolean | null
	productId: string
	isDangerousGood?: boolean | null
	quantity: number
	seller: string
	sizeId: string
	sku: string
	customizedData?: {
		__typename?: 'CustomizedData'
		collectionId?: string | null
		customColor?: string | null
		customPosition?: string | null
		customSize?: string | null
		customType?: string | null
		needleWork?: string | null
	} | null
	price: {
		__typename?: 'PriceDto'
		opi?: boolean | null
		promotionName?: string | null
		star?: boolean | null
		type?: string | null
		current: {
			__typename?: 'MoneyDto'
			amount: number
			currency: string
			decimals: number
		}
		initial: {
			__typename?: 'MoneyDto'
			amount: number
			currency: string
			decimals: number
		}
		original: {
			__typename?: 'MoneyDto'
			amount: number
			currency: string
			decimals: number
		}
	}
	productInfo?: {
		__typename?: 'ItemCheckoutProductInfo'
		colorName?: string | null
		image?: string | null
		name?: string | null
		sizeName?: string | null
	} | null
	promotion?: {
		__typename?: 'ItemPromotion'
		code?: string | null
		price?: {
			__typename?: 'MoneyDto'
			amount: number
			currency: string
			decimals: number
		} | null
	} | null
	mangoLikesDiscount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentItemFieldsFragment' }

export type FragmentDeliveriesFieldsFragment = {
	__typename?: 'DraftPurchaseDelivery'
	id?: string | null
	order?: number | null
	seller?: string | null
	warehouse?: string | null
	deliveryAddress?:
		| ({ __typename?: 'DeliveryAddress' } & {
				' $fragmentRefs'?: {
					FragmentDeliveryAddressFieldsFragment: FragmentDeliveryAddressFieldsFragment
				}
		  })
		| null
	deliveryDates?: {
		__typename?: 'DeliveryDates'
		from?: string | null
		to?: string | null
	} | null
	items?: Array<
		| ({ __typename?: 'ItemCheckoutDto' } & {
				' $fragmentRefs'?: {
					FragmentItemFieldsFragment: FragmentItemFieldsFragment
				}
		  })
		| null
	> | null
} & { ' $fragmentName'?: 'FragmentDeliveriesFieldsFragment' }

export type FragmentCountryFeaturesFragment = {
	__typename?: 'CountryFeatures'
	hasPremiumDeliveryMethods?: boolean | null
} & { ' $fragmentName'?: 'FragmentCountryFeaturesFragment' }

export type FragmentMangoLikesFragment = {
	__typename?: 'MangoLikes'
	minPointsNeeded?: number | null
	type?: MangoLikesType | null
	discount?: {
		__typename?: 'MoneyDto'
		amount: number
		currency: string
		decimals: number
	} | null
} & { ' $fragmentName'?: 'FragmentMangoLikesFragment' }

export type FragmentDraftPurchaseFieldsFragment = {
	__typename?: 'DraftPurchase'
	id: string
	userId: string
	currency: string
	billingAddressId?: string | null
	billingAddress?:
		| ({ __typename?: 'BillingAddress' } & {
				' $fragmentRefs'?: {
					FragmentBillingAddressFieldsFragment: FragmentBillingAddressFieldsFragment
				}
		  })
		| null
	deliveries: Array<
		| ({ __typename?: 'DraftPurchaseDelivery' } & {
				' $fragmentRefs'?: {
					FragmentDeliveriesFieldsFragment: FragmentDeliveriesFieldsFragment
				}
		  })
		| null
	>
	deliveryMethods?: Array<
		| ({ __typename?: 'DeliveryMethod' } & {
				' $fragmentRefs'?: {
					FragmentDeliveryMethodsFragment: FragmentDeliveryMethodsFragment
				}
		  })
		| null
	> | null
	giftVoucher?:
		| ({ __typename?: 'GiftVoucher' } & {
				' $fragmentRefs'?: {
					FragmentGiftVoucherFieldsFragment: FragmentGiftVoucherFieldsFragment
				}
		  })
		| null
	accountBalance?:
		| ({ __typename?: 'AccountBalance' } & {
				' $fragmentRefs'?: {
					FragmentAccountBalanceFieldsFragment: FragmentAccountBalanceFieldsFragment
				}
		  })
		| null
	selectedDeliveryMethod:
		| ({ __typename?: 'SelectedDropPointDeliveryMethod' } & {
				' $fragmentRefs'?: {
					FragmentSelectedDeliveryMethod_SelectedDropPointDeliveryMethod_Fragment: FragmentSelectedDeliveryMethod_SelectedDropPointDeliveryMethod_Fragment
				}
		  })
		| ({ __typename?: 'SelectedHomeDeliveryMethod' } & {
				' $fragmentRefs'?: {
					FragmentSelectedDeliveryMethod_SelectedHomeDeliveryMethod_Fragment: FragmentSelectedDeliveryMethod_SelectedHomeDeliveryMethod_Fragment
				}
		  })
		| ({ __typename?: 'SelectedMangoDeliveryMethod' } & {
				' $fragmentRefs'?: {
					FragmentSelectedDeliveryMethod_SelectedMangoDeliveryMethod_Fragment: FragmentSelectedDeliveryMethod_SelectedMangoDeliveryMethod_Fragment
				}
		  })
		| ({ __typename?: 'SelectedStoreDeliveryMethod' } & {
				' $fragmentRefs'?: {
					FragmentSelectedDeliveryMethod_SelectedStoreDeliveryMethod_Fragment: FragmentSelectedDeliveryMethod_SelectedStoreDeliveryMethod_Fragment
				}
		  })
	preSaleTotal?: { __typename?: 'MoneyDto'; amount: number } | null
	mangoLikes?:
		| ({ __typename?: 'MangoLikes' } & {
				' $fragmentRefs'?: {
					FragmentMangoLikesFragment: FragmentMangoLikesFragment
				}
		  })
		| null
	promotions?:
		| ({ __typename?: 'DraftPurchasePromotions' } & {
				' $fragmentRefs'?: {
					FragmentPromotionsFieldsFragment: FragmentPromotionsFieldsFragment
				}
		  })
		| null
	summary?:
		| ({ __typename?: 'Summary' } & {
				' $fragmentRefs'?: {
					FragmentSummaryFieldsFragment: FragmentSummaryFieldsFragment
				}
		  })
		| null
	communicationMessages?: Array<{
		__typename?: 'CommunicationMessage'
		id?: string | null
	} | null> | null
	countryFeatures?:
		| ({ __typename?: 'CountryFeatures' } & {
				' $fragmentRefs'?: {
					FragmentCountryFeaturesFragment: FragmentCountryFeaturesFragment
				}
		  })
		| null
} & { ' $fragmentName'?: 'FragmentDraftPurchaseFieldsFragment' }

export type UpdateDeliveryAddressMutationVariables = Exact<{
	updateDeliveryAddressInput: UpdateDeliveryAddressInput
}>

export type UpdateDeliveryAddressMutation = {
	__typename?: 'Mutation'
	updateDeliveryAddress?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type CreateDeliveryAddressMutationVariables = Exact<{
	createDeliveryAddressInput: CreateDeliveryAddressInput
}>

export type CreateDeliveryAddressMutation = {
	__typename?: 'Mutation'
	createDeliveryAddress?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type RemoveCheckoutItemMutationVariables = Exact<{
	removeItemInput: RemoveItemInput
}>

export type RemoveCheckoutItemMutation = {
	__typename?: 'Mutation'
	removeCheckoutItem?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type ApplyPromotionalCodeMutationVariables = Exact<{
	promotionalCodeInput: PromotionalCodeInput
}>

export type ApplyPromotionalCodeMutation = {
	__typename?: 'Mutation'
	applyPromotionalCode?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type CancelPromotionalCodeMutationVariables = Exact<{
	promotionalCodeInput: PromotionalCodeInput
}>

export type CancelPromotionalCodeMutation = {
	__typename?: 'Mutation'
	cancelPromotionalCode?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type ApplyGiftVoucherMutationVariables = Exact<{
	giftVoucherInput: GiftVoucherInput
}>

export type ApplyGiftVoucherMutation = {
	__typename?: 'Mutation'
	applyGiftVoucher?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type CancelGiftVoucherMutationVariables = Exact<{
	giftVoucherInput: GiftVoucherInput
}>

export type CancelGiftVoucherMutation = {
	__typename?: 'Mutation'
	cancelGiftVoucher?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type ApplyAccountBalanceMutationVariables = Exact<{
	channel: Scalars['String']['input']
}>

export type ApplyAccountBalanceMutation = {
	__typename?: 'Mutation'
	applyAccountBalance?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type CancelAccountBalanceMutationVariables = Exact<{
	channel: Scalars['String']['input']
}>

export type CancelAccountBalanceMutation = {
	__typename?: 'Mutation'
	cancelAccountBalance?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type SelectDeliveryMethodMutationVariables = Exact<{
	selectDeliveryMethodInput: SelectDeliveryMethodInput
}>

export type SelectDeliveryMethodMutation = {
	__typename?: 'Mutation'
	selectDeliveryMethod?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type SelectHomeDeliveryMethodMutationVariables = Exact<{
	selectHomeDeliveryMethodInput: SelectHomeDeliveryMethodInput
}>

export type SelectHomeDeliveryMethodMutation = {
	__typename?: 'Mutation'
	selectHomeDeliveryMethod?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type SelectedDraftPurchaseDeliveryMethodMutationVariables = Exact<{
	selectedDeliveryMethodInput: SelectedDeliveryMethodInput
}>

export type SelectedDraftPurchaseDeliveryMethodMutation = {
	__typename?: 'Mutation'
	selectedDraftPurchaseDeliveryMethod?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type RefreshDeliveryMethodsMutationVariables = Exact<{
	refreshDeliveryMethodsInput: RefreshDeliveryMethodsInput
}>

export type RefreshDeliveryMethodsMutation = {
	__typename?: 'Mutation'
	refreshDeliveryMethods?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type InitializeDraftPurchaseMutationVariables = Exact<{
	initializeCheckoutInput: InitializeCheckoutInput
}>

export type InitializeDraftPurchaseMutation = {
	__typename?: 'Mutation'
	initializeDraftPurchase?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type ApplyMangoLikesMutationVariables = Exact<{
	channel: Scalars['String']['input']
}>

export type ApplyMangoLikesMutation = {
	__typename?: 'Mutation'
	applyMangoLikes?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type CancelMangoLikesMutationVariables = Exact<{
	channel: Scalars['String']['input']
}>

export type CancelMangoLikesMutation = {
	__typename?: 'Mutation'
	cancelMangoLikes?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type RemoveDangerousItemsMutationVariables = Exact<{
	removeDangerousItemsInput: RemoveDangerousItemsInput
}>

export type RemoveDangerousItemsMutation = {
	__typename?: 'Mutation'
	removeDangerousItems?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export type GetDraftPurchaseQueryVariables = Exact<{
	getDraftPurchaseInput: GetDraftPurchaseInput
}>

export type GetDraftPurchaseQuery = {
	__typename?: 'Query'
	getDraftPurchase?:
		| ({ __typename?: 'DraftPurchase' } & {
				' $fragmentRefs'?: {
					FragmentDraftPurchaseFieldsFragment: FragmentDraftPurchaseFieldsFragment
				}
		  })
		| null
}

export class TypedDocumentString<TResult, TVariables>
	extends String
	implements DocumentTypeDecoration<TResult, TVariables>
{
	__apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType']

	constructor(
		private value: string,
		public __meta__?: Record<string, any>
	) {
		super(value)
	}

	toString(): string & DocumentTypeDecoration<TResult, TVariables> {
		return this.value
	}
}
export const FragmentItemsFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
    `,
	{ fragmentName: 'FragmentItemsFields' }
) as unknown as TypedDocumentString<FragmentItemsFieldsFragment, unknown>
export const FragmentSummaryDtoFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}
    `,
	{ fragmentName: 'FragmentSummaryDtoFields' }
) as unknown as TypedDocumentString<FragmentSummaryDtoFieldsFragment, unknown>
export const FragmentBillingAddressFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
    `,
	{ fragmentName: 'FragmentBillingAddressFields' }
) as unknown as TypedDocumentString<
	FragmentBillingAddressFieldsFragment,
	unknown
>
export const FragmentDeliveryAddressFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
    `,
	{ fragmentName: 'FragmentDeliveryAddressFields' }
) as unknown as TypedDocumentString<
	FragmentDeliveryAddressFieldsFragment,
	unknown
>
export const FragmentItemFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
    `,
	{ fragmentName: 'FragmentItemFields' }
) as unknown as TypedDocumentString<FragmentItemFieldsFragment, unknown>
export const FragmentDeliveriesFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
    fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}`,
	{ fragmentName: 'FragmentDeliveriesFields' }
) as unknown as TypedDocumentString<FragmentDeliveriesFieldsFragment, unknown>
export const FragmentDeliveryMethodsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
    `,
	{ fragmentName: 'FragmentDeliveryMethods' }
) as unknown as TypedDocumentString<FragmentDeliveryMethodsFragment, unknown>
export const FragmentGiftVoucherFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
    `,
	{ fragmentName: 'FragmentGiftVoucherFields' }
) as unknown as TypedDocumentString<FragmentGiftVoucherFieldsFragment, unknown>
export const FragmentAccountBalanceFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
    `,
	{ fragmentName: 'FragmentAccountBalanceFields' }
) as unknown as TypedDocumentString<
	FragmentAccountBalanceFieldsFragment,
	unknown
>
export const FragmentSelectedDeliveryMethodFragmentDoc =
	new TypedDocumentString(
		`
    fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
    `,
		{ fragmentName: 'FragmentSelectedDeliveryMethod' }
	) as unknown as TypedDocumentString<
		FragmentSelectedDeliveryMethodFragment,
		unknown
	>
export const FragmentMangoLikesFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
    `,
	{ fragmentName: 'FragmentMangoLikes' }
) as unknown as TypedDocumentString<FragmentMangoLikesFragment, unknown>
export const FragmentPromotionsFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
    `,
	{ fragmentName: 'FragmentPromotionsFields' }
) as unknown as TypedDocumentString<FragmentPromotionsFieldsFragment, unknown>
export const FragmentSummaryFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
    `,
	{ fragmentName: 'FragmentSummaryFields' }
) as unknown as TypedDocumentString<FragmentSummaryFieldsFragment, unknown>
export const FragmentCountryFeaturesFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
    `,
	{ fragmentName: 'FragmentCountryFeatures' }
) as unknown as TypedDocumentString<FragmentCountryFeaturesFragment, unknown>
export const FragmentDraftPurchaseFieldsFragmentDoc = new TypedDocumentString(
	`
    fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}`,
	{ fragmentName: 'FragmentDraftPurchaseFields' }
) as unknown as TypedDocumentString<
	FragmentDraftPurchaseFieldsFragment,
	unknown
>
export const AddItemDocument = new TypedDocumentString(`
    mutation AddItem($channel: String!, $item: ShoppingCartAddItemInput!, $orderBy: ShoppingCartOrderByInput) {
  addItem(channel: $channel, item: $item, orderBy: $orderBy) {
    items {
      ...FragmentItemsFields
    }
    deliveries {
      order
      items {
        ...FragmentItemsFields
      }
    }
    summary {
      ...FragmentSummaryDtoFields
    }
    totalItems
    metadata {
      limitsExceeded
    }
  }
}
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}`) as unknown as TypedDocumentString<AddItemMutation, AddItemMutationVariables>
export const MergeWithGuestDocument = new TypedDocumentString(`
    mutation MergeWithGuest($channel: String!, $orderBy: ShoppingCartOrderByInput) {
  mergeFrom(channel: $channel, orderBy: $orderBy) {
    merged
    items {
      ...FragmentItemsFields
    }
    deliveries {
      order
      items {
        ...FragmentItemsFields
      }
    }
    summary {
      ...FragmentSummaryDtoFields
    }
    totalItems
    metadata {
      limitsExceeded
    }
  }
}
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}`) as unknown as TypedDocumentString<
	MergeWithGuestMutation,
	MergeWithGuestMutationVariables
>
export const MergeWithGuestCleanupDocument = new TypedDocumentString(`
    mutation MergeWithGuestCleanup($channel: String!, $orderBy: ShoppingCartOrderByInput, $cleanup: Boolean) {
  mergeFrom(channel: $channel, orderBy: $orderBy, cleanup: $cleanup) {
    merged
  }
}
    `) as unknown as TypedDocumentString<
	MergeWithGuestCleanupMutation,
	MergeWithGuestCleanupMutationVariables
>
export const RemoveItemDocument = new TypedDocumentString(`
    mutation RemoveItem($channel: String!, $itemId: String!, $orderBy: ShoppingCartOrderByInput) {
  removeItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {
    items {
      ...FragmentItemsFields
    }
    deliveries {
      order
      items {
        ...FragmentItemsFields
      }
    }
    summary {
      ...FragmentSummaryDtoFields
    }
    totalItems
    metadata {
      limitsExceeded
    }
  }
}
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}`) as unknown as TypedDocumentString<
	RemoveItemMutation,
	RemoveItemMutationVariables
>
export const ClearItemDocument = new TypedDocumentString(`
    mutation ClearItem($channel: String!, $itemId: String!, $orderBy: ShoppingCartOrderByInput) {
  clearItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {
    items {
      ...FragmentItemsFields
    }
    deliveries {
      order
      items {
        ...FragmentItemsFields
      }
    }
    summary {
      ...FragmentSummaryDtoFields
    }
    totalItems
    metadata {
      limitsExceeded
    }
  }
}
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}`) as unknown as TypedDocumentString<
	ClearItemMutation,
	ClearItemMutationVariables
>
export const FindByUserDocument = new TypedDocumentString(`
    query FindByUser($channel: String!, $orderBy: ShoppingCartOrderByInput) {
  findByUser(channel: $channel, orderBy: $orderBy) {
    ... on ShoppingCartDto {
      totalItems
      deliveries {
        order
        items {
          ...FragmentItemsFields
        }
      }
      items {
        ...FragmentItemsFields
      }
      summary {
        ...FragmentSummaryDtoFields
      }
      metadata {
        limitsExceeded
      }
      merged
    }
  }
}
    fragment FragmentItemsFields on ItemDto {
  colorId
  customized
  itemId
  look
  price {
    current {
      currentPrice: amount
    }
    initial {
      initialPrice: amount
    }
    discountRate
    promotionName
    previous {
      lowest {
        promo {
          amount
        }
        sale {
          amount
        }
      }
      original {
        shop {
          amount
        }
        outlet {
          amount
        }
      }
    }
    star
    type
  }
  productId
  hasLowStock
  quantity
  seller
  sizeId
  outOfStock
  warehouses
}
fragment FragmentSummaryDtoFields on SummaryDto {
  deliveryCost {
    deliveryAmount: amount
  }
  freeShipping
  promoteShippingToStore
  subtotal {
    subTotalPrice: amount
  }
  total {
    totalPrice: amount
  }
  remainingCostToBeFreeShipping {
    untilFree: amount
  }
  taxable
  saved {
    amount
  }
  original {
    amount
  }
}`) as unknown as TypedDocumentString<FindByUserQuery, FindByUserQueryVariables>
export const FindTotalItemsByUserDocument = new TypedDocumentString(`
    query FindTotalItemsByUser($channel: String!) {
  findTotalItemsByUser(channel: $channel) {
    totalItems
  }
}
    `) as unknown as TypedDocumentString<
	FindTotalItemsByUserQuery,
	FindTotalItemsByUserQueryVariables
>
export const UpdateDeliveryAddressDocument = new TypedDocumentString(`
    mutation UpdateDeliveryAddress($updateDeliveryAddressInput: UpdateDeliveryAddressInput!) {
  updateDeliveryAddress(updateDeliveryAddressInput: $updateDeliveryAddressInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	UpdateDeliveryAddressMutation,
	UpdateDeliveryAddressMutationVariables
>
export const CreateDeliveryAddressDocument = new TypedDocumentString(`
    mutation CreateDeliveryAddress($createDeliveryAddressInput: CreateDeliveryAddressInput!) {
  createDeliveryAddress(createDeliveryAddressInput: $createDeliveryAddressInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	CreateDeliveryAddressMutation,
	CreateDeliveryAddressMutationVariables
>
export const RemoveCheckoutItemDocument = new TypedDocumentString(`
    mutation RemoveCheckoutItem($removeItemInput: RemoveItemInput!) {
  removeCheckoutItem(removeItemInput: $removeItemInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	RemoveCheckoutItemMutation,
	RemoveCheckoutItemMutationVariables
>
export const ApplyPromotionalCodeDocument = new TypedDocumentString(`
    mutation ApplyPromotionalCode($promotionalCodeInput: PromotionalCodeInput!) {
  applyPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	ApplyPromotionalCodeMutation,
	ApplyPromotionalCodeMutationVariables
>
export const CancelPromotionalCodeDocument = new TypedDocumentString(`
    mutation CancelPromotionalCode($promotionalCodeInput: PromotionalCodeInput!) {
  cancelPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	CancelPromotionalCodeMutation,
	CancelPromotionalCodeMutationVariables
>
export const ApplyGiftVoucherDocument = new TypedDocumentString(`
    mutation ApplyGiftVoucher($giftVoucherInput: GiftVoucherInput!) {
  applyGiftVoucher(giftVoucherInput: $giftVoucherInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	ApplyGiftVoucherMutation,
	ApplyGiftVoucherMutationVariables
>
export const CancelGiftVoucherDocument = new TypedDocumentString(`
    mutation CancelGiftVoucher($giftVoucherInput: GiftVoucherInput!) {
  cancelGiftVoucher(giftVoucherInput: $giftVoucherInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	CancelGiftVoucherMutation,
	CancelGiftVoucherMutationVariables
>
export const ApplyAccountBalanceDocument = new TypedDocumentString(`
    mutation ApplyAccountBalance($channel: String!) {
  applyAccountBalance(channel: $channel) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	ApplyAccountBalanceMutation,
	ApplyAccountBalanceMutationVariables
>
export const CancelAccountBalanceDocument = new TypedDocumentString(`
    mutation CancelAccountBalance($channel: String!) {
  cancelAccountBalance(channel: $channel) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	CancelAccountBalanceMutation,
	CancelAccountBalanceMutationVariables
>
export const SelectDeliveryMethodDocument = new TypedDocumentString(`
    mutation SelectDeliveryMethod($selectDeliveryMethodInput: SelectDeliveryMethodInput!) {
  selectDeliveryMethod(selectDeliveryMethodInput: $selectDeliveryMethodInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	SelectDeliveryMethodMutation,
	SelectDeliveryMethodMutationVariables
>
export const SelectHomeDeliveryMethodDocument = new TypedDocumentString(`
    mutation SelectHomeDeliveryMethod($selectHomeDeliveryMethodInput: SelectHomeDeliveryMethodInput!) {
  selectHomeDeliveryMethod(
    selectHomeDeliveryMethodInput: $selectHomeDeliveryMethodInput
  ) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	SelectHomeDeliveryMethodMutation,
	SelectHomeDeliveryMethodMutationVariables
>
export const SelectedDraftPurchaseDeliveryMethodDocument =
	new TypedDocumentString(`
    mutation SelectedDraftPurchaseDeliveryMethod($selectedDeliveryMethodInput: SelectedDeliveryMethodInput!) {
  selectedDraftPurchaseDeliveryMethod(
    selectedDeliveryMethodInput: $selectedDeliveryMethodInput
  ) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
		SelectedDraftPurchaseDeliveryMethodMutation,
		SelectedDraftPurchaseDeliveryMethodMutationVariables
	>
export const RefreshDeliveryMethodsDocument = new TypedDocumentString(`
    mutation RefreshDeliveryMethods($refreshDeliveryMethodsInput: RefreshDeliveryMethodsInput!) {
  refreshDeliveryMethods(
    refreshDeliveryMethodsInput: $refreshDeliveryMethodsInput
  ) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	RefreshDeliveryMethodsMutation,
	RefreshDeliveryMethodsMutationVariables
>
export const InitializeDraftPurchaseDocument = new TypedDocumentString(`
    mutation InitializeDraftPurchase($initializeCheckoutInput: InitializeCheckoutInput!) {
  initializeDraftPurchase(initializeCheckoutInput: $initializeCheckoutInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	InitializeDraftPurchaseMutation,
	InitializeDraftPurchaseMutationVariables
>
export const ApplyMangoLikesDocument = new TypedDocumentString(`
    mutation ApplyMangoLikes($channel: String!) {
  applyMangoLikes(channel: $channel) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	ApplyMangoLikesMutation,
	ApplyMangoLikesMutationVariables
>
export const CancelMangoLikesDocument = new TypedDocumentString(`
    mutation CancelMangoLikes($channel: String!) {
  cancelMangoLikes(channel: $channel) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	CancelMangoLikesMutation,
	CancelMangoLikesMutationVariables
>
export const RemoveDangerousItemsDocument = new TypedDocumentString(`
    mutation RemoveDangerousItems($removeDangerousItemsInput: RemoveDangerousItemsInput!) {
  removeDangerousItems(removeDangerousItemsInput: $removeDangerousItemsInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	RemoveDangerousItemsMutation,
	RemoveDangerousItemsMutationVariables
>
export const GetDraftPurchaseDocument = new TypedDocumentString(`
    query GetDraftPurchase($getDraftPurchaseInput: GetDraftPurchaseInput!) {
  getDraftPurchase(getDraftPurchaseInput: $getDraftPurchaseInput) {
    ...FragmentDraftPurchaseFields
  }
}
    fragment FragmentPromotionsFields on DraftPurchasePromotions {
  codes {
    type
    name
  }
  discount {
    amount
    currency
    decimals
  }
  freeShipping
  someItemsOnly
}
fragment FragmentDeliveryMethods on DeliveryMethod {
  category
  estimatedDelivery {
    dateFrom
    dateTo
  }
  id
  price {
    amount
    currency
    decimals
  }
  isAlternativeForDangerousGoods
  isPremium
}
fragment FragmentGiftVoucherFields on GiftVoucher {
  code
  balance {
    amount
    currency
    decimals
  }
  applied {
    amount
    currency
    decimals
  }
}
fragment FragmentAccountBalanceFields on AccountBalance {
  balance {
    amount
    currency
    decimals
  }
  used {
    amount
    currency
    decimals
  }
  remaining {
    amount
    currency
    decimals
  }
  leftToPay {
    amount
    currency
    decimals
  }
  applied
}
fragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {
  ... on SelectedHomeDeliveryMethod {
    deliveryMethodId
    deliveryAddressId
    hasDangerousGoodsRestrictions
  }
  ... on SelectedStoreDeliveryMethod {
    deliveryMethodId
    storeId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedMangoDeliveryMethod {
    deliveryMethodId
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
  ... on SelectedDropPointDeliveryMethod {
    deliveryMethodId
    dropPointId
    dropPointData {
      dropPointName
      dropPointAddress
      dropPointCity
      dropPointPostalCode
    }
    pickingData {
      address
      city
      countryCode
      name
      surname
      email
      phone
      phonePrefix
      postalCode
      provinceId
      provinceName
    }
    hasDangerousGoodsRestrictions
  }
}
fragment FragmentSummaryFields on Summary {
  loyaltyDiscount {
    amount
    currency
    decimals
  }
  promoDiscount {
    amount
    currency
    decimals
  }
  giftVoucherAmount {
    amount
    currency
    decimals
  }
  shipping {
    amount
    currency
    decimals
  }
  subtotal {
    amount
    currency
    decimals
  }
  subtotalWithShipping {
    amount
    currency
    decimals
  }
  total {
    amount
    currency
    decimals
  }
  totalItems
  taxes {
    amount
    currency
    decimals
  }
  areTaxesCalculated
}
fragment FragmentDeliveryAddressFields on DeliveryAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentBillingAddressFields on BillingAddress {
  address1
  address2
  address3
  areaCode
  city
  country
  email
  firstName
  fullAddress
  id
  lastName
  phoneNumber
  state
  tin
  zipCode
  isValidAddress
}
fragment FragmentItemFields on ItemCheckoutDto {
  channel
  colorId
  customized
  customizedData {
    collectionId
    customColor
    customPosition
    customSize
    customType
    needleWork
  }
  itemId
  look
  outOfStock
  price {
    current {
      amount
      currency
      decimals
    }
    initial {
      amount
      currency
      decimals
    }
    opi
    original {
      amount
      currency
      decimals
    }
    promotionName
    star
    type
  }
  productId
  productInfo {
    colorName
    image
    name
    sizeName
  }
  promotion {
    code
    price {
      amount
      currency
      decimals
    }
  }
  mangoLikesDiscount {
    amount
    currency
    decimals
  }
  isDangerousGood
  quantity
  seller
  sizeId
  sku
}
fragment FragmentDeliveriesFields on DraftPurchaseDelivery {
  deliveryAddress {
    ...FragmentDeliveryAddressFields
  }
  deliveryDates {
    from
    to
  }
  id
  items {
    ...FragmentItemFields
  }
  order
  seller
  warehouse
}
fragment FragmentCountryFeatures on CountryFeatures {
  hasPremiumDeliveryMethods
}
fragment FragmentMangoLikes on MangoLikes {
  discount {
    amount
    currency
    decimals
  }
  minPointsNeeded
  type
}
fragment FragmentDraftPurchaseFields on DraftPurchase {
  id
  userId
  currency
  billingAddressId
  billingAddress {
    ...FragmentBillingAddressFields
  }
  deliveries {
    ...FragmentDeliveriesFields
  }
  deliveryMethods {
    ...FragmentDeliveryMethods
  }
  giftVoucher {
    ...FragmentGiftVoucherFields
  }
  accountBalance {
    ...FragmentAccountBalanceFields
  }
  selectedDeliveryMethod {
    ...FragmentSelectedDeliveryMethod
  }
  preSaleTotal {
    amount
  }
  mangoLikes {
    ...FragmentMangoLikes
  }
  promotions {
    ...FragmentPromotionsFields
  }
  summary {
    ...FragmentSummaryFields
  }
  communicationMessages {
    id
  }
  countryFeatures {
    ...FragmentCountryFeatures
  }
}`) as unknown as TypedDocumentString<
	GetDraftPurchaseQuery,
	GetDraftPurchaseQueryVariables
>
